import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('div',{staticClass:"donation-form-container"},[_c(VSheet,{staticClass:"donation-form-box",attrs:{"elevation":"4"}},[_c('div',{staticClass:"title-section"},[_c('h2',[_vm._v("Donate to TOAD marketing")]),_c('br'),_c(VDivider),_c('br'),_c('p',[_vm._v("If you wish to help us promote TOAD, you can donate to the multisig wallet managed by our marketing team.")]),_c('p',{staticStyle:{"color":"orange","font-weight":"bold"}},[_vm._v("Important: donations are only accepted on "),_c('u',[_vm._v("Moonbeam")]),_vm._v(" network")])],1),_c(VDivider),_c('div',{staticClass:"donation-address-container"},[_c(VTextField,{attrs:{"value":_vm.donationAddress,"readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('u',[_vm._v("Moonbeam")]),_vm._v(" donation wallet address: ")]},proxy:true},{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"open-on-hover":false,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"min-width":"0"},attrs:{"icon":"","retain-focus-on-click":""},on:{"click":[on.click,function($event){return _vm.copyText(_vm.donationAddress)}]}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-clipboard-multiple")])],1)]}}])},[_c('span',[_vm._v("Copied!")])])]},proxy:true}])}),_c('div',{staticClass:"funds-raised"},[_c(VCard,{staticClass:"funds-raised-total",attrs:{"elevation":"4"}},[_c('h4',[_vm._v("Funds in marketing wallet:")]),(_vm.usdDonationAmount > 0)?_c('span',[_vm._v(" $"+_vm._s(_vm.usdDonationAmount)+" ")]):_vm._e()])],1),_c(VDivider),_c('div',{staticClass:"counted-token-list"},[_c('span',[_vm._v("The following tokens are counted:")]),_c('p',[_vm._v("GLMR, TOAD, PAD, USDC, USDT, BUSD, ETH")]),_c('span',[_vm._v("Other tokens are also accepted, but might not be calculated here.")])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }