
import Vue from 'vue'
import { ethers } from 'ethers'
import { providers } from '@0xsequence/multicall'
import AwaitLock from 'await-lock'
import { List } from 'linq-collections'

import Farm from '@/components/Farm.vue'
import SliderTabs from '@/components/SliderTabs.vue'
import {
  MULTICALL_ADDRESS,
  ERC20_ABI,
} from '../constants'
import { IEcosystem, EcosystemId, ECOSYSTEMS } from '@/ecosystem'
import { formatMixin } from '@/format'
import { delay, equalsInsensitive, toFloat } from '@/utils'

function tryParseUrl(url: string) {
  try {
    return new URL(url)
  } catch {
    return null
  }
}

export default Vue.extend({
  name: 'Home',
  mixins: [formatMixin],
  components: { Farm, SliderTabs },
  data() {
    return {
      active: true,
      sortBy: 'Earned',
      searchText: '',
      usdDonationAmount: 0.0,
      donationAddress: '0xc0a458c02de2f82ee78c93b91b045703bc2c35b3'
    }
  },
  async mounted() {
    this.$store.commit('setEcosystemId', 2)
      const ecosystem = this.ecosystem
      const multicall = this.multicall
      const priceModel = ecosystem.priceModel

      const blockNumber = await multicall.getBlockNumber()
      let promises = [
        priceModel.syncWithin(blockNumber, 12),
      ]

      await Promise.all(promises)

      const acceptedTokens : string[] = [
      '0xe3db50049c74de2f7d7269823af3178cf22fd5e3', // WGLMR
      '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b', // USDC (anySwap)
      '0x8f552a71efe5eefc207bf75485b356a0b3f01ec9', // USDC (xPollinate)
      '0x8e70cd5b4ff3f62659049e74b6649c6603a0e594', // USDT
      '0x30d2a9f5fdf90ace8c17952cbb4ee48a55d916a7', // ETH (xPollinate)
      '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f', // ETH (anySwap)
      '0x59193512877e2ec3bb27c178a8888cfac62fb32d', // GLMR PAD
      '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA', // TOAD
      '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F', // BUSD

      ]

      let tokenUsdAmount = 0.0

      for (const tokenAddress of acceptedTokens) {
        const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, multicall)
        const tokenBalance = await tokenContract.balanceOf(this.donationAddress)
        tokenUsdAmount += parseFloat(ethers.utils.formatEther(tokenBalance)) * priceModel.getPriceUsd(tokenAddress)
      }

      let ethBalance = await multicall.getBalance(this.donationAddress)
      let ethUsdAmount = parseFloat(ethers.utils.formatEther(ethBalance)) * priceModel.getPriceUsd('0xe3db50049c74de2f7d7269823af3178cf22fd5e3')

      this.usdDonationAmount = Math.floor(tokenUsdAmount + ethUsdAmount)
  },
  beforeDestroy() {
    this.active = false
  },
  beforeRouteLeave(to: any, from: any, next: Function) {
    this.active = false
    next()
  },
  computed: {
    ecosystemId: {
      get(): EcosystemId {
        return this.$store.state.ecosystemId
      },
      set(val: EcosystemId) {
        this.$store.commit('setEcosystemId', val)
      }
    },
    ecosystem(): IEcosystem {
      return this.$store.getters.ecosystem
    },
    multicall(): ethers.providers.Provider {
      return new providers.MulticallProvider(this.ecosystem.dataseed, {
        batchSize: 300,
        timeWindow: 0,
        contract: MULTICALL_ADDRESS
      })
    },
    isConnected(): boolean {
      return this.$store.getters.isConnected
    },
    userAddress(): string {
      return this.$store.state.address
    },
    lastChainTransactionBlock(): Object {
      // access properties explicitly to trigger reactivity
      return Object.entries(this.$store.state.lastChainTransactionBlock)
    }
  },
  methods: {
    copyText (text : string) {
      let textArea = document.createElement("textarea")
      textArea.value = text
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      let successful = document.execCommand('copy')

      document.body.removeChild(textArea)
    },
  }
})
